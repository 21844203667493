<template>
  <div class="main">
    <!--左侧导航栏-->
    <div class="left">
      <p>建站系统</p>
      <McMenu></McMenu>
    </div>
    <div class="right">
      <!--头部-->
      <CommonHead></CommonHead>

      <!-- tag路由标签 -->
      <CommonTag></CommonTag>

      <!-- 内容区域 -->
      <el-main>
        <!-- 子路由出口 -->
        <router-view></router-view>
      </el-main>
    </div>
  </div>
</template>

<script>
import CommonHead from '../../components/CommonHead.vue'
import CommonTag from '../../components/CommonTag.vue'
import McMenu from '@/components/McMenu.vue'

export default {
  name: 'Main',
  components: {
    McMenu,
    CommonHead,
    CommonTag
  }
}
</script>

<style lang="less" scoped>
.main{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.main > .right{
  width: 89%;
  display: flex;
  flex-direction: column;
}
.main> .left{
  width: 11%;
  display: flex;
  flex-direction: column;
  background-color: rgb(84, 92, 100);
  color: rgb(255, 255, 255);
}

.main> .left > p{
  width: 100%;
  height: 1.5vw;
  line-height: 1.5vw;
  text-align: center;
  font-size: 1vw;
}

.el-header {
  padding: 0;
}
/*
/deep/ .el-main {
  // !？？？？为什么height给任意高度就可以使用？
  height: 5px;
  overflow: auto;
}

// 去除滚动条
::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}*/
</style>
